import React from 'react';

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <h1>Jewl AI 💎</h1> {/* Linkes Element: Fett geschriebenes "Jewl AI" */}
      </div>
    </header>
  );
}

export default Header;